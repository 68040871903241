import { ROUTES } from 'shared/constants/routes';
import { commonMessages } from 'shared/intl/common.intl';
import { getPrivacyPolicyUrl, getRefundPolicyUrl, getTermsOfUseUrl } from 'shared/utils/policy';

import { messages } from './Navigation.intl';

export const texts = {
  signout: messages.signout,
};

export const pages = [
  {
    title: messages.app,
    url: ROUTES.INSTALL_APP.url,
    activeRegExp: /^(\/)?$/,
  },
  {
    title: messages.accountSettings,
    url: ROUTES.ACCOUNT_PROFILE.url,
    activeRegExp: /\/account/,
  },
];

export const legalLinks = [
  {
    title: commonMessages['common.termsOfUse.title'],
    getHref: getTermsOfUseUrl,
  },
  {
    title: commonMessages['common.privacyPolicy.title'],
    getHref: getPrivacyPolicyUrl,
  },
  {
    title: commonMessages['common.refundPolicy.title'],
    getHref: getRefundPolicyUrl,
  },
];
