import { useEffect } from 'react';

import { AuthStatus, useAuthUser } from 'app/providers/AuthUserProvider';

import { logger } from 'shared/services/logger';
import { UserModel } from 'shared/types/UserModel';

import { analyticService } from '../service';
import { userReadyService } from '../userReadyService';

export const useAnalytic = () => {
  const {
    models: { user, authStatus },
  } = useAuthUser();

  useEffect(() => {
    if (user?.gender) {
      localStorage.setItem('gender', user?.gender);
    }
    if (user?.country) {
      localStorage.setItem('country', user?.country);
    }
  }, [user?.country, user?.gender]);

  useEffect(() => {
    if (!user && authStatus === AuthStatus.DONE) {
      userReadyService.resolve(null);
    }
  }, [authStatus, user]);

  useEffect(() => {
    if (!user) return;

    setUserData(user).then(() => {
      userReadyService.resolve(null);
    });
  }, [user]);
};

const setUserData = async (user: UserModel) => {
  const { Provider } = (await analyticService.loadAnalyticModule()) || {};

  await Provider?.provideData({
    country: () => user.country,
    abTestName: () => null,
    clientHandlerFn: (error: unknown) =>
      logger.error(new Error('Analytic error: ', { cause: error })),
  });

  await Provider?.setUserId(user.id as unknown as number);
};
