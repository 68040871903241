import { defineMessages } from 'react-intl';

export const commonMessages = defineMessages({
  'common.termsOfUse.title': {
    defaultMessage: 'Terms of Use',
    id: 'Cn5zGt',
    description: 'Terms of Use title',
  },
  'common.privacyPolicy.title': {
    defaultMessage: 'Privacy Notice',
    id: 'trABU4',
    description: 'Privacy Notice title',
  },
  'common.refundPolicy.title': {
    defaultMessage: 'Refund Policy',
    id: 'gSn/jQ',
    description: 'Refund Policy title',
  },
  'common.contactUs.title': {
    id: 'MvYXSx',
    defaultMessage: 'Contact us',
    description: 'Contact us',
  },
  'common.user.gender': {
    id: 'j38X94',
    defaultMessage: 'Gender',
    description: 'Text field label',
  },
  'common.user.age': {
    id: 'LLD5VR',
    defaultMessage: 'Age',
    description: 'Text field label',
  },
  'common.user.units': {
    id: 'SL9tUQ',
    defaultMessage: 'Units',
    description: 'Text field label (lbs/kg/in/cm)',
  },
  'common.user.current_weight': {
    id: 'o3OuHa',
    defaultMessage: 'Current weight',
    description: 'Text field label',
  },
  'common.user.target_weight': {
    id: 'WXAree',
    defaultMessage: 'Target weight',
    description: 'Text field label',
  },
  'common.user.height': {
    id: '+0NtM5',
    defaultMessage: 'Height',
    description: 'Text field label',
  },
  'common.button.save.title': {
    id: 'qDE0kb',
    defaultMessage: 'Save',
    description: 'Button: Title',
  },
  'common.error.message.invalidNumberRange': {
    id: 'YJ70mV',
    defaultMessage: 'Please enter a number between {from} and {to} 👆',
    description: 'Error message: Out of range value',
  },
  'common.error.message.string.onlyLetters': {
    id: 'Tk4Jss',
    defaultMessage: '{label} must only contain letters',
    description: 'String error message: invalid data',
  },

  'common.error.message.email.empty': {
    id: '60imlD',
    defaultMessage: 'Enter your email address',
    description: 'Email error message: Empty value',
  },
  'common.error.message.password.empty': {
    id: '0Cx/2z',
    defaultMessage: 'Enter your password',
    description: 'Email error message: Empty value',
  },
  'common.error.message.email.invalid': {
    id: 'xo4nzE',
    defaultMessage: 'The email address is not valid',
    description: 'Email error message: Invalid value',
  },
  'common.error.message.oops': {
    id: 'X8cuuG',
    defaultMessage: 'Oops, something went wrong',
    description: 'Email error message: Non-unique value',
  },
  'common.error.message.invalidCredentials': {
    id: 'f1ZP/3',
    defaultMessage: 'Email or password is invalid',
    description: 'Email error message: invalid credentials',
  },

  'common.snackbar.success': {
    id: 'aHEFM1',
    defaultMessage: 'Changes successfully applied',
    description: 'Snackbar: success message',
  },
  'common.snackbar.error': {
    id: 'bP4F5+',
    defaultMessage: 'Changes not applied',
    description: 'Snackbar: error message',
  },
});
