export const PUBLIC_ROUTES = {
  RESET_PASSWORD: {
    url: '/reset-password',
  },

  LOG_IN: {
    url: '/login',
  },
} as const;

export const PRIVATE_ROUTES = {
  INSTALL_APP: {
    // url: '/install-app',
    // while there is no cabinet functionality - show install app page as home
    url: '/',
  },

  ANDROID_DISCLAIMER: {
    url: '/android-disclaimer',
  },

  HOME: {
    url: '/',
  },
  ACCOUNT: {
    url: '/account',
  },

  ACCOUNT_SETTINGS: {
    url: '/account/settings',
  },

  ACCOUNT_SUBSCRIPTIONS: {
    url: '/account/subscriptions',
  },

  ACCOUNT_PROFILE: {
    url: '/account/profile',
  },
} as const;

export const ROUTES = {
  ...PRIVATE_ROUTES,
  ...PUBLIC_ROUTES,
} as const;
