import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ErrorBoundary } from 'shared/components/ErrorBoundary';
import { PrivateRoute } from 'shared/components/PrivateRoute/PrivateRoute';

import { AccountProfileSkeleton } from 'pages/account-profile/components/AccountProfileSkeleton';
import { SubscriptionCardSkeleton } from 'pages/account-subscriptions/components/SubscriptionCardSkeleton';

import { useDocumentSocialWebViewBrowser } from 'shared/hooks/useDocumentOperations';

import { ROUTES } from 'shared/constants/routes';
import { useAnalytic } from 'shared/services/analytics/hooks/useAnalytic';

import { RootLayout } from './RootLayout';
import { withProviders } from './providers/withProviders';

const AndroidDisclaimer = lazy(() => import('pages/android-disclaimer'));
// const Home = lazy(() => import('pages/home'));
const ResetPassword = lazy(() => import('pages/reset-password'));
const ThankYouPage = lazy(() => import('pages/install-app'));
const AccountSettings = lazy(() => import('pages/account-settings'));
const LogIn = lazy(() => import('pages/log-in'));
const AccountProfile = lazy(() => import('pages/account-profile'));
const AccountSubscriptions = lazy(() => import('pages/account-subscriptions'));

const AppComponent = () => {
  useDocumentSocialWebViewBrowser();
  useAnalytic();

  return (
    <ErrorBoundary>
      <RootLayout>
        <Suspense fallback={null}>
          <Routes>
            {/* <Route path={ROUTES.HOME.url} element={<Home />} /> */}
            <Route path={ROUTES.RESET_PASSWORD.url} element={<ResetPassword />} />
            <Route
              path={ROUTES.INSTALL_APP.url}
              element={
                <PrivateRoute>
                  <ThankYouPage />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.ANDROID_DISCLAIMER.url}
              element={
                <PrivateRoute>
                  <AndroidDisclaimer />
                </PrivateRoute>
              }
            />
            <Route path={ROUTES.LOG_IN.url} element={<LogIn />} />
            <Route
              path={ROUTES.ACCOUNT.url}
              element={
                <PrivateRoute>
                  <AccountSettings />
                </PrivateRoute>
              }
            >
              <Route
                path={ROUTES.ACCOUNT_PROFILE.url}
                element={
                  <PrivateRoute>
                    <Suspense fallback={<AccountProfileSkeleton />}>
                      <AccountProfile />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.ACCOUNT_SUBSCRIPTIONS.url}
                element={
                  <PrivateRoute>
                    <Suspense fallback={<SubscriptionCardSkeleton />}>
                      <AccountSubscriptions />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path={ROUTES.ACCOUNT_SETTINGS.url}
                element={
                  <PrivateRoute>
                    <Suspense fallback={null}>
                      <div>Account Settings</div>
                    </Suspense>
                  </PrivateRoute>
                }
              />
            </Route>
          </Routes>
        </Suspense>
      </RootLayout>
    </ErrorBoundary>
  );
};

export const App = withProviders(AppComponent);
