import React from 'react';
import styled from 'styled-components';

import { TypographyVariant, TypographyFontWeight } from 'ui-elements/themes/types';
import {
  getTypographyFont,
  getTypographyFontWeight,
  getTypographyLetterSpacing,
  getTypographyTextTransform,
} from 'ui-elements/themes/typography';

/**
 * Default mapping to element by variant
 */
const defaultVariantMapping: Partial<Record<TypographyVariant, string>> = {
  caption1: 'span',
};

export type TypographyColor =
  | 'currentColor'
  | 'text-primary'
  | 'text-secondary'
  | 'text-secondary-subdued'
  | 'text-accent'
  | 'text-accent-subdued'
  | 'text-subdued'
  | 'text-critical'
  | 'text-success'
  | 'text-disabled'
  | 'text-on-primary'
  | 'text-on-disabled'
  | 'red400'
  | 'black80'
  | 'grey900';

/**
 * Typography component props
 */
export interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  as?: React.ElementType;
  children?: React.ReactNode;
  className?: string;
  color?: TypographyColor;
  display?: 'initial' | 'block' | 'inline' | 'inline-block';
  gutterTop?: number;
  gutterBottom?: number;
  variant?: TypographyVariant;
  weight?: TypographyFontWeight;
  textTransform?: React.CSSProperties['textTransform'];
  textWrap?: React.CSSProperties['textWrap'];
}

interface StyledTypographyProps {
  $align: Required<TypographyProps>['align'];
  $color: Required<TypographyProps>['color'];
  $display: Required<TypographyProps>['display'];
  $gutterTop: Required<TypographyProps>['gutterTop'];
  $gutterBottom: Required<TypographyProps>['gutterBottom'];
  $variant: Required<TypographyProps>['variant'];
  $textTransform: Required<TypographyProps>['textTransform'];
  $weight?: Required<TypographyProps['weight']>;
  $textWrap?: TypographyProps['textWrap'];
}

const StyledTypography = styled.span<StyledTypographyProps>`
  margin: 0;
  text-align: ${({ $align }) => $align};
  font: ${({ $variant }) => getTypographyFont($variant)};
  letter-spacing: ${({ $variant }) => getTypographyLetterSpacing($variant)};
  text-transform: ${({ $variant }) => getTypographyTextTransform($variant)};
  color: ${({ theme, $color }) => ($color === 'currentColor' ? $color : theme.color($color))};
  ${({ $display }) => ($display !== 'initial' ? `display: ${$display};` : '')}
  ${({ $gutterTop }) => ($gutterTop ? `margin-top: ${$gutterTop}px;` : '')}
  ${({ $gutterBottom }) => ($gutterBottom ? `margin-bottom: ${$gutterBottom}px;` : '')}
  ${({ $textTransform }) => ($textTransform !== 'none' ? `text-transform: ${$textTransform};` : '')}
  ${({ $weight }) => $weight && `font-weight: ${getTypographyFontWeight($weight)} ;`}
  ${({ $textWrap }) => $textWrap && `text-wrap: ${$textWrap};`}
`;

/**
 * Renders Typography component that allows to use basic text styles
 */
const Typography = ({
  align = 'inherit',
  as,
  color = 'text-primary',
  display = 'initial',
  gutterTop = 0,
  gutterBottom = 0,
  variant = 'caption1',
  textTransform = 'none',
  textWrap,
  weight,
  ...rest
}: TypographyProps): JSX.Element => {
  const element = as || defaultVariantMapping[variant] || 'span';

  return (
    <StyledTypography
      $align={align}
      as={element}
      $color={color}
      $display={display}
      $gutterTop={gutterTop}
      $gutterBottom={gutterBottom}
      $variant={variant}
      $textTransform={textTransform}
      $weight={weight}
      $textWrap={textWrap}
      {...rest}
    />
  );
};

export { Typography };
