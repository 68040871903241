import { logger } from 'shared/services/logger';
import { withResolvers } from 'shared/utils/promise';

import { changePathNameToScreenId } from './helpers/location';
import { patchAnalyticUtils } from './helpers/patchAnalyticPackage';
import { userReadyService } from './userReadyService';

import type { ActionEventAction, ElementEventAction } from 'wikr-core-analytics';

interface TrackClickData {
  elementEvent?: ElementEventAction;
  actionEvent?: ActionEventAction;
  screenIdParam?: string;
  eventLabel?: string | string[];
}

export const AnalyticService = () => {
  const { promise: initializedModule, resolve } =
    withResolvers<typeof import('wikr-core-analytics')>();
  let isInitialized = false;
  let isInitializing = false;

  const loadAnalyticModule = async () => {
    try {
      return await import('wikr-core-analytics');
    } catch (error: unknown) {
      logger.error(new Error('wikr-core-analytics loading failed'));

      return null;
    }
  };

  const initAnalytics = async () => {
    const { VITE_PUBLIC_AWS_DELIVERY_STREAM, VITE_PUBLIC_AWS_ID } = import.meta.env;

    if (!VITE_PUBLIC_AWS_ID) return;

    isInitializing = true;

    const module = await loadAnalyticModule();
    if (!module) {
      isInitializing = false;
      return;
    }

    const { Main, systemNames, AnalyticUtils } = module;

    patchAnalyticUtils(AnalyticUtils);

    Main.initSystems({
      activeSystems: [
        {
          name: systemNames.amazon,
          id: VITE_PUBLIC_AWS_ID,
          config: {
            releaseDate: window.APP_BUILD_DATE?.substring(0, 10),
            streamName: VITE_PUBLIC_AWS_DELIVERY_STREAM,
            envMode: process.env.APP_ENV ?? 'stage',
          },
        },
      ],
    });

    await userReadyService.promise;

    resolve(module);
    isInitialized = true;
    isInitializing = false;
  };

  const getInitializedModule = async () => {
    if (!isInitialized && !isInitializing) {
      await initAnalytics();
    }

    return initializedModule;
  };

  const trackScreenLoad = async (screenCustomId?: string) => {
    const screenId = screenCustomId ?? changePathNameToScreenId();

    const { Main, EventTrigger, ElementEventAction, ActionEventAction } =
      await getInitializedModule();

    Main.trackNew({
      eventData: { event: EventTrigger.screenLoad, screenId },
      actionData: {
        elementEvent: ElementEventAction.screen,
        actionEvent: ActionEventAction.load,
      },
    });
  };

  const trackClick = async ({
    elementEvent,
    actionEvent,
    screenIdParam,
    eventLabel,
  }: TrackClickData) => {
    const screenId = screenIdParam ?? changePathNameToScreenId();

    const { Main, EventTrigger } = await getInitializedModule();

    Main.trackNew({
      eventData: { event: EventTrigger.click, screenId },
      ...(elementEvent && actionEvent && { actionData: { elementEvent, actionEvent } }),
      ...(eventLabel && { data: { event_label: eventLabel } }),
    });
  };

  const clearUserData = async () => {
    const { Provider } = await getInitializedModule();

    userReadyService.resetUserReadyPromise();

    return Provider.provideData({
      userId: null,
      gender: '',
      country: async () => null,
      abTestName: async () => null,
    });
  };

  return {
    initAnalytics,
    clearUserData,
    trackClick,
    trackScreenLoad,
    getInitializedModule,
    loadAnalyticModule,
  };
};

export const analyticService = AnalyticService();
