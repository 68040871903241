import React from 'react';

import * as S from './styled';

type Props = {
  width: string;
  className?: string;
};

export const TextSkeleton = ({ width, className }: Props) => {
  // invisible text for span to have correct height
  return (
    <S.TextSkeleton className={className} $width={width}>
      a
    </S.TextSkeleton>
  );
};
