import React from 'react';

import { Image } from 'shared/components/Image';

import { Button } from 'ui-elements/Button';
import { Divider } from 'ui-elements/Divider';
import { TextSkeleton } from 'ui-elements/Skeleton';
import { Stack } from 'ui-elements/Stack';
import { Typography } from 'ui-elements/Typography';

import { Row } from './Row';

import { config } from '../config';

export const SubscriptionCardSkeleton = () => (
  <div className="flex flex-col items-stretch gap-2 rounded-lg border-2 border-solid border-border-secondary bg-red-0 p-4">
    <Stack direction="row" justifyContent="flex-start" gap={8}>
      <Image
        sources={config.images.appLogo.sources}
        src={config.images.appLogo.src}
        alt="app logo"
        width={30}
        height={30}
      />
      <Typography variant="heading3">
        <TextSkeleton width="120px" />
      </Typography>
    </Stack>
    <Divider width={1} className="!mb-4 !mt-1" color="border-secondary" />

    <TextSkeleton width="75px" className="h-7" />

    <Typography variant="heading3">
      <TextSkeleton width="100px" />
    </Typography>

    <Row leftText={<TextSkeleton width="60px" />} rightText={<TextSkeleton width="120px" />} />
    <Row leftText={<TextSkeleton width="70px" />} rightText={<TextSkeleton width="120px" />} />

    <Divider width={1} className="!mb-4 !mt-4" color="border-secondary" />

    <TextSkeleton width="120px" className="h-7" />

    <Typography variant="heading3">
      <TextSkeleton width="100px" />
    </Typography>

    <Row leftText={<TextSkeleton width="70px" />} rightText={<TextSkeleton width="120px" />} />
    <Row leftText={<TextSkeleton width="80px" />} rightText={<TextSkeleton width="120px" />} />
    <Row leftText={<TextSkeleton width="120px" />} rightText={<TextSkeleton width="120px" />} />

    <Divider width={1} className="!mb-4 !mt-4" color="border-secondary" />

    <Button variant="plain" size="small" className="self-start">
      <TextSkeleton width="120px" />
    </Button>
  </div>
);
