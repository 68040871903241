import cx from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { useAuthUser } from 'app/providers/AuthUserProvider';
import { Divider } from 'ui-elements/Divider';

import { NavItem } from './components/NavItem';
import { Overlay } from './components/Overlay';

import { legalLinks, pages, texts } from './config';

export const Navigation = ({ visible, closeMenu }: { visible: boolean; closeMenu: () => void }) => {
  const { locale } = useIntl();
  const location = useLocation();
  const {
    models: { authenticated },
    operations: { signout },
  } = useAuthUser();

  const handleSignoutClick = () => {
    closeMenu();
    signout();
  };

  if (!authenticated) {
    return null;
  }

  return (
    <div>
      <Overlay visible={visible} onClick={closeMenu} />
      <nav
        className={cx(
          'fixed -left-full top-14 z-10 h-full bg-surface-default px-3 pt-10',
          'w-[218px] transition-[left] duration-300',
          'xl:left-0 xl:w-[300px] xl:shadow-none',
          visible && '!left-0 shadow-inner'
        )}
      >
        <ul className="flex flex-col gap-2">
          {pages.map(({ title, url, activeRegExp }) => (
            <NavItem
              key={title.id}
              href={url}
              title={title}
              isActive={activeRegExp.test(location.pathname)}
              onClick={closeMenu}
            />
          ))}
          <Divider color="border-secondary-default" className="!mb-2 !mt-2" width={1} />
          {legalLinks.map((link) => (
            <NavItem
              key={link.title.id}
              href={link.getHref(locale)}
              target="_blank"
              title={link.title}
              small
              onClick={closeMenu}
            />
          ))}
          <NavItem href="#" title={texts.signout} onClick={handleSignoutClick} small />
          <Divider color="border-secondary-default" className="!mb-2 !mt-2" width={1} />
        </ul>
      </nav>
    </div>
  );
};
